<template>
  <div >
    <div class="text-left" v-if="selectedItem === null">
      <div class="flex items-center">
        <div class="w-2/3 flex text-left text-c48 items-center font-c6">
          <icon
              :data="icons.back"
              height="70"
              width="70"
              class="mr-2 cursor-pointer"
              original
              @click="$emit('oga', false)"
          />
          <div class="ml-4">
            Bon à lancer
          </div>
        </div>
      </div>


      <div class="bg-white p-6 mt-10 rounded-5 bloc text-left">
        <div class="flex bloc text-90 text-c5 text-c16">
          <div class="w-1/3">
            FOURNISSEURS
          </div>
<!--          <div class="w-1/4 ml-4">-->
<!--            QTÉ DE COMMANDES-->
<!--          </div>-->
          <div class="w-1/3 ml-4">
            NOMBRE DE VERRES
          </div>
          <div class="w-1/3 ml-4">
            NUMERO
          </div>
          <div class="w-20 ml-4"/>
        </div>

        <div class="diviser mt-6" />

        <div class="flex justify-center mt-6" v-if="charge">
          <easy-spinner
              type="dots"
              size="60"
          />
        </div>

        <div v-if="stock.length === 0 && charge" class="text-c18 text-60 text-center mt-10">
          Aucune donnée pour le moment
        </div>


        <div v-if="stock.length > 0">
          <div v-for="(i, index) in stock" :key="index" @click="selected(i)">
            <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer mt-6" >
              <div class="w-1/3">
                {{ i.supplier }}
              </div>

  <!--            <div class="w-1/4 ml-4 flex items-center">-->
  <!--                <div class="text-c16">-->
  <!--                 {{ i.orders }}-->
  <!--                </div>-->
  <!--            </div>-->

              <div class="w-1/3 flex items-center ml-4">
                <div class="text-c16">
                  {{ i.glass.length }}
                </div>
              </div>

              <div class="w-1/3 ml-4">
                {{ i.number }}
              </div>

              <div class="w-20 ml-4">
                <icon
                    :data="icons.upload"
                    height="15"
                    width="15"
                    class="cursor-pointer"
                    original
                />
              </div>
            </div>
            <div class="diviser mt-6" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedItem !== null">
      <fournisor :donnes="selectedItem" v-if="selectedItem !== null" @oga="retour"/>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import upload from '../../../assets/icons/download.svg'
import fournisor from './detailFournisseur'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",

  components: {
    fournisor
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        upload
      },
      stock: [],
      selectedItem: null,
      charge: true
    }
  },

  created () {
    this.allData()
  },

  methods: {
    selected (item) {
      this.selectedItem = item
    },

    allData () {
      this.charge = true
      console.log('iciiii')
      // http.get(apiroutes.baseURL + apiroutes.getExport)
      http.get(apiroutes.baseURL + apiroutes.exportLite)
          .then(response => {
            console.log('stock success')
            // console.log(response)
            this.stock = response.data
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retour (answer) {
      this.selectedItem = null
      if (answer) {
        this.allData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser {
  height: 1px;
  background-color: #DCDCE4;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
</style>
