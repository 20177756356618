<template>
  <div class="the_header">
    <div class="button">
      <export-excel
          class= "btn btn-default"
          :data= "json_data"
          :fields= "json_fields"
          worksheet= "My Worksheet"
          name= "filename.xls"
      >
        <bouton label="Exporter la fiche" border="1px solid #DDDDDD" background="rgba(242, 245, 249, 0.5)" color="#3521B5" size="16px" :icon="icons.esport"/>
      </export-excel>
    </div>
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import esport from '../../../assets/icons/export.svg'

export default {
  name: 'Index',
  components: {
    bouton
  },

  props: {
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        esport
      },
      loaderColor: '#fff',
      charge: false,
      json_fields: {
        'NOM ET PRENOMS DU CLIENT': 'client',
        'CÔTÉ DE L\'ŒIL': 'oeil',
        'QUANTITÉ': 'qte',
        'LIBELLÉ DU VERRE': 'name',
        'CODE COMMERCIAL': 'code',
        'INDICE A LIVRER': 'indice',
        'SPHERE A LIVRER': 'sphere',
        'CYLINDRE A LIVRER': 'cylindre',
        'TRAITEMENT DUR': 'dur',
        'TRAITEMENT AR': 'ar',
        'DATE DE LA COMMANDE': 'date',
        'TYPE DE MONTURE': 'monture'
      },

      json_data: [
      ],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },

  mounted () {
    const data = this.donne.glass
    for (let item in data) {
      if (data[item].valid === undefined || data[item].valid === true) {
        const obj = {}
        obj.client = data[item].client.name + ' ' + data[item].client.surname

        obj.qte = data[item].quantity


        obj.code = data[item].product.ref
        obj.indice = data[item].product.indice
        obj.date = new Date().toLocaleDateString()

        if (data[item].meta !== undefined) {
          if (data[item].meta.oeil !== undefined && data[item].meta.oeil !== null) {
            obj.oeil = data[item].meta.oeil.substr(1,1)
          }

          if (data[item].meta.treatmentData !== undefined && data[item].meta.treatmentData !== null) {

            obj.dur = data[item].meta.treatmentData.dur
            obj.ar = data[item].meta.treatmentData.ar

            //Nom du verre
            obj.name = data[item].product.name + ' Mat ' + data[item].product.glassMaterial + ' Ind ' + data[item].product.indice +
                ' Sc ' + data[item].product.glassDetails.underCoat + ' Tdur ' + data[item].product.glassDetails.treatmentDur + ' AR ' +
                data[item].product.glassDetails.treatmentAr + ' Sph ' + data[item].meta.treatmentData.sphere +
                ' Cyl ' + data[item].meta.treatmentData.cylindre +
                ' Add ' + data[item].meta.treatmentData.addition


            // Transpose session
            let sphere = ''
            let cylindre = ''

            if (data[item].matchData !== null) {
              sphere = data[item].matchData.sphereOut
              cylindre = data[item].matchData.cylinderOut
            }

            obj.sphere = sphere
            obj.cylindre = cylindre
          }
        }

        if (data[item].frame) {
          if (data[item].frame.product.frameType === 'CERCLEE' && data[item].frame.product.frameMaterial === 'METALLIC') {
            obj.monture = 'CERCLEE METALIQUE'
          }

          if (data[item].frame.product.frameType === 'CERCLEE' && data[item].frame.product.frameMaterial === 'ACETATE') {
            obj.monture = 'CERCLEE ACETATE'
          }

          if (data[item].frame.product.frameType === 'NYLOR') {
            obj.monture = 'NYLOR'
          }

          if (data[item].frame.product.frameType === 'PERCEE') {
            obj.monture = 'PERCEE'
          }
        }

        this.json_data.push(obj)
      }
    }
  },

  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.the_header{
  font-family: 'Roboto', sans-serif;
  color: #000000;
}
.buttons{
  height: 2rem;
}
</style>
