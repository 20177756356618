<template>
  <div class="the_header">
    <div class="button">
      <export-excel
          class= "btn btn-default"
          :data= "json_data"
          :fields= "json_fields"
          worksheet= "My Worksheet"
          name= "filename.xls"
      >
        <bouton label="Exporter la fiche" border="1px solid #DDDDDD" background="rgba(242, 245, 249, 0.5)" color="#3521B5" size="16px" :icon="icons.esport"/>
      </export-excel>
    </div>
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import esport from '../../../assets/icons/export.svg'

export default {
  name: 'Index',
  components: {
    bouton
  },

  props: {
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        esport
      },
      loaderColor: '#fff',
      charge: false,
      json_fields: {
        'ORDER NO': 'client',
        'LR': 'oeil',
        'MATERIAL': 'material',
        'LENS TYPE': 'name',
        'TYPE': 'code',
        'QTY': 'qte',
        'ED': 'diametre',
        'HC': 'couche',
        'HMC': 'ar',
        'SPHERE': 'sphere',
        'CYLINDER': 'cylindre',
        'AXIS': 'ax',
        'ADDITION': 'addition',
        'BASIS': 'o',
        'DATE COMMANDE': 'date',
        'FRAME': 'monture',
        'A': 'largeur',
        'B': 'hauteur',
        'DBL': 'pont',
        'PD': 'ecart',
        'HT': 'montage',
        'OBSERVATIONS': 'services',
      },

      json_data: [
      ],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },

  mounted () {
    const data = this.donne.glass
    for (let item in data) {
      if (data[item].valid === undefined || data[item].valid === true) {
        const obj = {}
        if (data[item].client){
          obj.client = data[item].client.name + ' ' + data[item].client.surname
        } else {
          obj.client = '-'
        }

        obj.qte = data[item].quantity

        obj.material = data[item].product.glassMaterial

        obj.indice = data[item].product.indice
        obj.date = new Date().toLocaleDateString()
        obj.code = data[item].product.meta.supplier.code

        if (data[item].meta !== undefined) {
          if (data[item].meta.oeil !== undefined && data[item].meta.oeil !== null) {
            if (data[item].meta.oeil === 'OD') {
              obj.oeil = 'R'
            }
            if (data[item].meta.oeil === 'OG') {
              obj.oeil = 'L'
            }
          }

          if (data[item].meta.treatmentData !== undefined && data[item].meta.treatmentData !== null) {
            // name of product
            obj.name = data[item].product.meta.supplier.brand + ' ' + data[item].product.meta.supplier.indice + ' '

            obj.ar = data[item].product.meta.supplier.coating
            // ADD treatment
            if (data[item].treatment) {
              let treat = data[item].treatment

              for (let u in treat) {
                if (treat[u].product.meta) {
                  if (treat[u].product.meta.intituleFournisseur !== undefined){
                    if (treat[u].product.treatmentCategory === 'MASS') {
                      obj.name = obj.name + treat[u].product.meta.intituleFournisseur
                    } else {
                      obj.ar = obj.ar + ' ' + treat[u].product.meta.intituleFournisseur
                    }
                  }
                }
              }
            }

            if (data[item].meta.treatmentData.color === '') {
              obj.name = obj.name + ' CLEAR'
            }

            if (data[item].meta.treatmentData.color === 'ROSE') {
              obj.name = obj.name + ' PINK'
            }

            if (data[item].meta.treatmentData.color === 'VIOLET') {
              obj.name = obj.name + ' PURPLE'
            }

            if (data[item].meta.treatmentData.color === 'BLEU') {
              obj.name = obj.name + ' BLUE'
            }

            if (data[item].meta.treatmentData.color === 'BRUN') {
              obj.name = obj.name + ' BROWN'
            }

            if (data[item].meta.treatmentData.color === 'GRIS') {
              obj.name = obj.name + ' GRAY'
            }

            if (data[item].meta.treatmentData.color === 'VERT') {
              obj.name = obj.name + ' GREEN'
            }

            if (data[item].meta.treatmentData.color === 'ORANGE') {
              obj.name = obj.name + ' ORANGE'
            }

            if (data[item].meta.treatmentData.color === 'ROUGE') {
              obj.name = obj.name + ' RED'
            }

            obj.diametre = data[item].meta.treatmentData.diametre
            obj.couche = data[item].meta.treatmentData.sousCouche
            obj.ax = data[item].meta.treatmentData.axe
            obj.addition = data[item].meta.treatmentData.addition

            obj.sphere = data[item].meta.treatmentData.sphere
            obj.cylindre = data[item].meta.treatmentData.cylindre

            obj.dur = data[item].meta.treatmentData.dur

          }
        }

      // frame data
        if (data[item].frame) {
          if (data[item].frame.product.frameType === 'CERCLEE' && data[item].frame.product.frameMaterial === 'METALLIC') {
            obj.monture = 'Metal'
          }

          if (data[item].frame.product.frameType === 'CERCLEE' && data[item].frame.product.frameMaterial === 'ACETATE') {
            obj.monture = 'Plastic'
          }

          if (data[item].frame.product.frameType === 'NYLOR') {
            obj.monture = 'Semi-Rimless'
          }

          if (data[item].frame.product.frameType === 'PERCEE') {
            obj.monture = 'Rimless'
          }
        }

        // Observation
        let serv = ''
        if (data[item].service !== null) {
          let service = data[item].service
          for (let u in service) {
            if (service[u].product.meta) {
              if (service[u].product.meta.intituleFournisseur !== undefined){
                serv = serv + ' ' + service[u].product.meta.intituleFournisseur ? service[u].product.meta.intituleFournisseur : ''
              }
            }
          }
        }

        obj.services = serv

        // mesure
        if (data[item].clientMeasure !== null){
          if (obj.oeil === 'R') {
            obj.largeur = data[item].clientMeasure.od.lCalibre
            obj.hauteur = data[item].clientMeasure.od.hCalibre
            obj.pont = data[item].clientMeasure.od.pont
            obj.ecart = data[item].clientMeasure.od.ecart
            obj.montage = data[item].clientMeasure.od.hMontage
          } else {
            obj.largeur = data[item].clientMeasure.og.lCalibre
            obj.hauteur = data[item].clientMeasure.og.hCalibre
            obj.pont = data[item].clientMeasure.og.pont
            obj.ecart = data[item].clientMeasure.og.ecart
            obj.montage = data[item].clientMeasure.og.hMontage
          }
        }

        this.json_data.push(obj)
      }
    }
  },

  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.the_header{
  font-family: 'Roboto', sans-serif;
  color: #000000;
}
.buttons{
  height: 2rem;
}
</style>
