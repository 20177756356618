<template>
  <div class="text-left">
    <div class="flex items-center">
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="$emit('oga', false)"
        />
        <div class="ml-4">
          Fournisseur {{ info.supplier }}
        </div>
      </div>
    </div>


    <div class="bg-white p-6 mt-10 pb-20 rounded-5 bloc text-left">
      <div class="flex mt-4">
        <div class="w-4/5 text-c24 font-c5">{{ info.number }} | NOMBRE DE VERRES : {{ info.glass.length }}</div>
        <div class="w-1/5" @click="exportation" v-if="info.glass.length > 0">
          <excelOho :donne="info" v-if="info.supplier.toUpperCase() === 'STOCK OHO'"/>
          <excel-vlab :donne="info" v-if="info.supplier.toUpperCase() === 'VLAB'"/>
          <excel-con :donne="info" v-if="info.supplier.toUpperCase() === 'CONANT'"/>
        </div>
      </div>

      <div class="w-full flex text-90 text-c16 font-c6 text-left mt-10">
        <div class="w-1/4">
          INFO COMMERCIAL
        </div>
        <div class="w-1/4 ml-4">
          NOM FNSEUR
        </div>
        <div class="w-68 ml-4">
          IND.
        </div>
        <div class="w-68 ml-4">
          SPH.
        </div>
        <div class="w-68 ml-4">
          CYL.
        </div>
        <div class="w-68 ml-4">
          AXE
        </div>
        <div class="w-79 ml-4">
          ADD.
        </div>
        <div class="w-68 ml-4">
          DIA
        </div>
        <div class="w-1/6 ml-4">
          PATIENT
        </div>
        <div class="w-20 ml-4" />
        <div />
      </div>

      <div class="diviser mt-6"/>

      <div class="flex justify-center mt-12" v-if="charge && info.glass.length === 0">
        <easy-spinner
            type="dots"
            size="30"
        />
      </div>

      <div v-for="(item, index) in info.glass" :key="index">
        <div
            class="w-full flex items-center text-c16 font-c4 text-left mt-6 item cursor-pointer"
        >
          <div class="w-1/4">
            <div>
             <b v-if="item.meta && info.supplier.toUpperCase() !== 'CONANT'">({{ item.meta.oeil }})</b> {{ item.product.glassType }} {{item.product.ref}}
            </div>

            <span v-if="item.product.glassDetails !== undefined && item.product.glassDetails !== null">
              <span v-if="item.product.glassDetails.glassGen !== undefined">{{ item.product.glassDetails.glassGen }}</span>
              <span v-if="item.product.glassDetails.glassGen === undefined || item.product.glassDetails.glassGen === null">-</span>
            </span>
          </div>

          <div class="w-1/4 ml-4">
            {{ item.product.meta.supplier.brand }} {{ item.product.meta.supplier.indice }}

            <!--                    traitement de masse -->
              <span class="ml-2" v-if="item.treatment !== undefined && item.treatment !== null">
                      <span v-for="(treat, id) in item.treatment" :key="id">
                        <span v-if="treat.product.meta">
                          <span v-if="treat.product.meta.intituleFournisseur !== undefined">
                              {{treat.product.meta.intituleFournisseur}}
                          </span>
                        </span>
                      </span>
                    </span>

            <span v-if="item.meta !== null" class="ml-2">
              <span v-if="item.meta.treatmentData !== undefined">
                <span v-if="item.meta.treatmentData.color === ''">CLEAR</span>
                <span v-if="item.meta.treatmentData.color === 'ROSE'">PINK</span>
                 <span v-if="item.meta.treatmentData.color === 'VIOLET'">PURPLE</span>
                 <span v-if="item.meta.treatmentData.color === 'BLEU'">BLUE</span>
                 <span v-if="item.meta.treatmentData.color === 'BRUN'">BROWN</span>
                 <span v-if="item.meta.treatmentData.color === 'GRIS'">GRAY</span>
                 <span v-if="item.meta.treatmentData.color === 'VERT'">GREEN</span>
                <span v-if="item.meta.treatmentData.color === 'ORANGE'">ORANGE</span>
                <span v-if="item.meta.treatmentData.color === 'ROUGE'">RED</span>
              </span>
            </span>
          </div>

          <div class="w-68 ml-4">
            {{item.product.indice}}
          </div>


          <div class="w-68 ml-4">
            <span v-if="item.meta && item.meta.treatmentData !== undefined">
              <span v-if="item.meta.treatmentData !== null"> {{item.meta.treatmentData.sphere}}</span>

            </span>
            <span v-if="!item.meta.treatmentData">
              -
            </span>
          </div>

          <div class="w-68 ml-4">
           <span v-if="item.meta && item.meta.treatmentData !== undefined">
              {{item.meta.treatmentData.cylindre}}
            </span>
            <span v-if="!item.meta.treatmentData">
              -
            </span>
          </div>

          <div class="w-68 ml-4">
            <span v-if="item.meta && item.meta.treatmentData !== undefined">
              {{item.meta.treatmentData.axe}}
            </span>
            <span v-if="!item.meta.treatmentData">
              -
            </span>
          </div>

          <div class="w-79 ml-4">
            <span v-if="item.meta && item.meta.treatmentData !== undefined">
              {{item.meta.treatmentData.addition}}
            </span>
            <span v-if="!item.meta.treatmentData">
              -
            </span>
          </div>

          <div class="w-68 ml-4">
            <span v-if="item.meta && item.meta.treatmentData !== undefined">
              {{item.meta.treatmentData.diametre}}
            </span>
            <span v-if="!item.meta.treatmentData">
              -
            </span>
          </div>

          <div class="w-1/6 ml-4 text-yel">
            <span v-if="item.client">{{item.client.name}} {{item.client.surname}}</span>
          </div>

          <div class="w-20 ml-4">
            <icon
                v-if="item.valid === undefined || item.valid === true"
                :data="icons.delet"
                height="25"
                width="25"
                class="icone"
                @click="remove(index)"
                original
            />

            <icon
                v-if="item.valid !== undefined && item.valid === false"
                :data="icons.add"
                height="25"
                width="25"
                class="icone"
                @click="valid(index)"
                original
            />
          </div>
        </div>

        <div class="diviser mt-6"/>
      </div>

    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import delet from '../../../assets/icons/delete.svg'
import add from '../../../assets/icons/ajout.svg'
import excelOho from './excelOho'
import excelVlab from './excelVlab'
import excelCon from './excelCon'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",

  components: {
    excelOho,
    excelVlab,
    excelCon
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        delet,
        add
      },
      info: [],
      // allVerre: verre,
      charge: false,
      fournisseur: null
    }
  },

  created () {
    this.info = this.donnes
    this.fournisseur = this.donnes.supplier
   console.log(this.info)
  },

  methods: {
    retourAdd () {
      this.$router.push('/panier')
    },

    remove (index) {
      this.info.glass[index].valid = false
      this.info.supplier = 'cedric'
      setTimeout(()=>{
        this.info.supplier = this.fournisseur
      },1000);
    },

    valid (index) {
      this.info.glass[index].valid = true
      this.info.supplier = 'cedric'
      setTimeout(()=>{
        this.info.supplier = this.fournisseur
      },1000);
    },

    exportation () {
      let tab = []
      const data = this.info.glass
      for (let item in data) {
        if (data[item].valid === undefined || data[item].valid === true){
          tab.push(data[item].id)
        }
      }

      this.save(tab)
    },

    save (tab) {
      http.post(apiroutes.baseURL + apiroutes.createExport, {
        ids: tab
      })
          .then(response => {
            // console.log('success')
            // console.log(response)
            this.$emit('oga', true)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.diviser {
  height: 1px;
  background-color: #DCDCE4;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
</style>
