<template>
  <div class="p-10 pr-16 all">
    <filtre
        :activation="activFiltre"
        v-if="activFiltre"
        @oga="backFiltre"
    />

    <div v-if="activeBon === false">
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Commandes <span v-if="user !== null">({{ user }})</span>
          </div>
        </div>

        <div class="w-1/2 flex justify-end pl-12">
          <div class="w-1/3 mr-4">
            <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                :value-t="valueFiltre"
                :option="filtre"
                width="100%"
                @info="retourFiltre"
            />
          </div>

          <bouton
              class="w-1/3"
              label="Filtrage poussé"
              radius="5.4px"
              height="54px"
              weight="600"
              size="16px"
              background="#3521B5"
              @info="activFiltre = true"
          />

          <div class="w-1/3 ml-2">
            <bouton
                label="Bon à lancer"
                radius="5.4px"
                height="54px"
                weight="600"
                size="16px"
                @oga="activeBon = true"
            />
          </div>
        </div>
      </div>

      <div class="mt-6">
        <global-view
          v-if="!reloadSession"
          :filtre="valueFiltre"
          @info="retourEtape"
          @user="retourUser"
          :allFilter="filtrage"
          :temoinFilter="temoin"
        />
      </div>
    </div>

    <div v-if="activeBon">
      <bon @oga="activeBon = false"/>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/commandes/globalData'
import bon from '../../component/cdevs/commandes/aLancer'
import filter from '../../assets/icons/filter.svg'
import multiselect from '../../component/helper/form/multiselect'
import filtre from "@/component/popup/filtre";

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    bon,
    multiselect,
    filtre
  },

  data () {
    return {
      icons: {
        back,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      activeBon: false,
      filtre: ['À valider', 'Validées', 'Rejetées', 'Archivées'],
      valueFiltre: 'À valider',
      activFiltre: false,
      filtrage: null,
      temoin: null
    }
  },

  created () {
  },

  methods: {
    backFiltre (answer) {
      if (answer !== false) {
        this.filtrage = answer
        this.temoin = new Date().getTime()
        this.valueFiltre = 'À valider'
      }
      this.activFiltre = false
    },

    retourFiltre (answer) {
      this.valueFiltre = answer
    },

    retourUser (answer) {
      this.user = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 700px;
}
</style>
