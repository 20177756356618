<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/6">
            Nº DE RÉF.
          </div>
          <div class="w-1/5 ml-4">
            CLIENT
          </div>
          <div class="w-1/6 ml-4">
            EDITION
          </div>
          <div class="w-1/6 ml-4">
            LIVRAISON
          </div>
          <div class="w-1/6 ml-4 text-left">
            AGENCE
          </div>
          <div class="w-1/6 ml-4">
            MONTANT
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div v-if="tracker.length === 0 && !charge" class="mt-10 text-center text-60">
          Aucune commande disponible dans cette catégorie
        </div>

        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="prev"
            original
        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="next"
            original
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
// import commandeListe from './commandeListe.json'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    filtre: {
      type: String,
      default: null
    },
    allFilter: {
      type: Object,
      default: null
    },

    temoinFilter: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        next,
        prev
      },
      devis: [],
      charge: true,
      temoin: null,

      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      secondTemoin: null,
      statusCom: 'TECHNIQUE'
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.devis
      if (this.filtre !== this.temoin){
        let status = 'TECHNIQUE'
        if (this.filtre === 'Validées'){
          status = 'ACCOUNTING'
        }
        if (this.filtre === 'Rejetées'){
          status = 'TECHNIQUE_REJECT'
        }
        if (this.filtre === 'Archivées'){
          status = 'DELIVERED'
        }
        this.allCommande(status)
      }

      if (this.allFilter && this.secondTemoin !== this.temoinFilter) {
        this.allCommande()
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.secondTemoin = this.temoinFilter
      }

      return retour
    }
  },

  created () {
    this.allCommande(this.statusCom)
    this.temoin = this.filtre
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allCommande(this.statusCom)
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allCommande(this.statusCom)
      }
    },

    allCommande (status) {
      this.temoin = this.filtre
      this.charge = true

      let obj = null
      if (this.allFilter) {
        obj = this.allFilter
      } else {
        obj = {}
        obj.state = status
        this.statusCom = status
      }
      obj.page = this.page
      obj.limit = this.limite

      // console.log(obj)

      http.post(apiroutes.baseURL + apiroutes.getCommandePaginate, obj)
          .then(response => {
            // console.log(response)
            this.devis = response.data
            this.charge = false
            this.$emit('user', response.total)

            const page = parseInt(response.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.data.statusCode === 401){
              this.$router.go('/commandes')
            }
          })
    },

    selected (item) {
      const url = window.location.origin + '/detailsCommande?x=' + item.id
      window.open(url, "_blank");
      // this.$router.push({ path: '/detailsCommande', query: { x: item.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
