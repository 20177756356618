<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ item.reference }}
      </div>

      <div class="w-1/5 ml-4 flex items-center">
        <nameLabel
            :first="item.estimate.client.surname"
            :second="item.estimate.client.name"
            height="32px"
            width="32px"
            size="12px"
        />
        <div class="ml-2">{{item.estimate.client.name}} {{item.estimate.client.surname}}</div>
      </div>

      <div class="w-1/6 flex items-center ml-4">
          <div class="text-c16">
            {{ new Date(item.created_at).toLocaleDateString() }}
          </div>
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="!item.estimate.meta || !item.estimate.meta.updateAt">
          {{ expirationDate(item.created_at) }}
        </span>
        <span v-if="item.estimate.meta && item.estimate.meta.updateAt">
          {{ expirationDate(item.estimate.meta.updateAt) }}
        </span>
      </div>

      <div class="w-1/6 ml-4">
        {{ item.estimate.agency.label }}
      </div>

      <div class="w-1/6 ml-4">
        {{ item.estimate.financialData.totalAmount.toLocaleString() }} F
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import nameLabel from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
    nameLabel
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
    amount: function () {
      let amount = (this.item.financialData.totalAmount + this.item.financialData.deliveryAmount)-(this.item.financialData.discountAmount + this.item.financialData.supportAmount)
      return amount.toLocaleString()
    }
  },

  methods: {
    expirationDate (date) {
      const temps = 10 * (1000 * 3600 * 24)
      const expire = new Date(date).getTime() + temps
      return new Date(expire).toLocaleDateString()
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
