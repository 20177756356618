<template>
  <div class="the_header">
    <div class="button">
      <export-excel
          class= "btn btn-default"
          :data= "json_data"
          :fields= "json_fields"
          worksheet= "My Worksheet"
          name= "filename.xls"
      >
        <bouton label="Exporter la fiche" border="1px solid #DDDDDD" background="rgba(242, 245, 249, 0.5)" color="#3521B5" size="16px" :icon="icons.esport"/>
      </export-excel>
    </div>
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import esport from '../../../assets/icons/export.svg'

export default {
  name: 'Index',
  components: {
    bouton
  },

  props: {
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        esport
      },
      loaderColor: '#fff',
      charge: false,
      json_fields: {
        'Patient Name': 'client',
        'Tray ID': 'o',
        'Purchase ID': 'o',
        'Frame Style': 'fram',
        'Frame Type': 'monture',
        'Frame Sourse': 'source',
        'Edge (Yes/No)': 'edge',
        'A': 'largeur',
        'B': 'hauteur',
        'ED': 'calibre',
        'DBL': 'pont',
        'Diameter-H': 'diametre',
        'Diameter-V': 'o',
        'VD': 'distance',
        'Tilt': 'angle',
        'Wrap': 'galbe',
        'SPH R': 'sphereR',
        'SPH L': 'sphereL',
        'CYL R': 'cylindreR',
        'CYL L': 'cylindreL',
        'AXIS R': 'axR',
        'AXIS L': 'axL',
        'ADD R': 'additionR',
        'ADD L': 'additionL',
        'DPD R': 'ecartR',
        'DPD L': 'ecartL',
        'NPD R': 'o',
        'NPD L': 'o',
        'FH R': 'montageR',
        'FH L': 'montageL',
        'SH R': 'o',
        'SH L': 'o',
        'Prism1 R': 'o',
        'Prism1 L': 'o',
        'IN/OUT R': 'o',
        'IN/OUT L': 'o',
        'Prism2 R': 'o',
        'Prism2 L': 'o',
        'UP/DOWN R': 'o',
        'UP/DOWN L': 'o',
        'BASE R': 'o',
        'BASE L': 'o',
        'CT R': 'o',
        'CT L': 'o',
        'ET R': 'o',
        'ET L': 'o',
        'DEC_X R': 'o',
        'DEC_X L': 'o',
        'DEC_Y R': 'o',
        'DEC_Y L': 'o',
        'PRISM R': 'o',
        'PRISM L': 'o',
        'ANGLE R': 'o',
        'ANGLE L': 'o',
        'Lens Material': 'lens',
        'Lens Option': 'durFour',
        'Lens Type': 'name',
        'Treatment': 'arFour',
        'Tint Type': 'addFour',
        'Color1': 'o',
        'Color2': 'o',
        'Customize': 'o',
        'Emergency (Yes/No)': 'o',
        'Remark': 'services',
        'Quatity': 'qte',
        'UV400': 'addFourState',
        'DATE': 'date',
      },

      json_data: [
      ],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
    }
  },

  mounted () {
    const tempData = this.donne.glass
    for (let o in tempData){
      const id = tempData[o].order
      for (let j in tempData) {
        if (id === tempData[j].order && j > o) {
          tempData[o].meta.ogTreatment = tempData[j].meta.treatmentData
          tempData.splice(j, 1)
        }
      }
    }
    console.log('finaly', tempData)
    const data = tempData


    for (let item in data) {
      if (data[item].valid === undefined || data[item].valid === true) {
        const obj = {
          fram: 'Optical Fram',
          source: 'Uncut',
          edge: 'No'
        }
        obj.client = data[item].client.name + ' ' + data[item].client.surname

        obj.qte = data[item].quantity

        obj.material = data[item].product.glassMaterial

        obj.lens = data[item].product.glassMaterial + ' ' + data[item].product.meta.supplier.indice

        obj.name = data[item].product.meta.supplier.code + ' ' + data[item].product.meta.supplier.brand
        obj.durFour = data[item].product.meta.supplier.indice + '(' + this.trackColor(data[item].meta.treatmentData.color) + '-' + this.trackColor(data[item].meta.ogTreatment.color) + ')'

        const ar = data[item].product.glassDetails.treatmentAr

        if (ar === 'HMC') {
          obj.arFour = 'HMC'
        }

        if (ar === '0') {
          obj.arFour = 'HC'
        }

        if (ar === 'SATIN+') {
          obj.arFour = 'SH-HT'
        }

        if (ar === 'HC') {
          obj.arFour = 'UC'
        }

        obj.indice = data[item].product.indice
        obj.date = new Date().toLocaleDateString()

        if (data[item].meta !== undefined) {
          if (data[item].meta.treatmentData !== undefined && data[item].meta.treatmentData !== null) {
            obj.diametre = data[item].meta.treatmentData.diametre
            obj.couche = data[item].meta.treatmentData.sousCouche

            obj.axR = data[item].meta.treatmentData.axe
            obj.axL = data[item].meta.ogTreatment.axe

            obj.additionR = data[item].meta.treatmentData.addition
            obj.additionL = data[item].meta.ogTreatment.addition

            obj.sphereR = data[item].meta.treatmentData.sphere
            obj.sphereL = data[item].meta.ogTreatment.sphere

            obj.cylindreR = data[item].meta.treatmentData.cylindre
            obj.cylindreL = data[item].meta.ogTreatment.cylindre

            obj.dur = data[item].meta.treatmentData.dur
            obj.ar = data[item].meta.treatmentData.ar
          }
        }

        if (data[item].frame) {
          if (data[item].frame.product.frameType === 'CERCLEE' && data[item].frame.product.frameMaterial === 'METALLIC') {
            obj.monture = 'Metal'
          }

          if (data[item].frame.product.frameType === 'CERCLEE' && data[item].frame.product.frameMaterial === 'ACETATE') {
            obj.monture = 'Plastic'
          }

          if (data[item].frame.product.frameMaterial === 'METALLIC ALLOY ACETATE') {
            obj.monture = 'Plastic-Metal'
          }

          if (data[item].frame.product.frameType === 'NYLOR') {
            obj.monture = 'Semi-Rimless'
          }

          if (data[item].frame.product.frameType === 'PERCEE') {
            obj.monture = 'Rimless'
          }
        }

        if (data[item].clientMeasure !== null){
          obj.largeur = data[item].clientMeasure.od.lCalibre
          obj.hauteur = data[item].clientMeasure.od.hCalibre
          obj.calibre = data[item].clientMeasure.od.lCalibre + 3
          obj.pont = data[item].clientMeasure.od.pont
          obj.distance = data[item].clientMeasure.od.dVerre
          obj.angle = data[item].clientMeasure.od.aPant
          obj.galbe = data[item].clientMeasure.od.gMonture

          obj.ecartR = data[item].clientMeasure.od.ecart
          obj.ecartL = data[item].clientMeasure.og.ecart
          obj.montageR = data[item].clientMeasure.od.hMontage
          obj.montageL = data[item].clientMeasure.og.hMontage
        }

        const treatment = data[item].treatment

        obj.addFour = ''
        obj.addFourState = 'N'
        for (let i in treatment) {
          if (treatment[i].product.name.toLowerCase().indexOf('teinte') > -1) {
            obj.addFour =  obj.addFour + ' ' + treatment[i].product.meta.intituleFournisseur
          }

          if (treatment[i].product.name.toLowerCase() === 'uv 400'){
            obj.addFourState = 'Y'
          }
        }

        // Les services
        let serv = ''
        if (data[item].service !== null) {
          let service = data[item].service
          for (let u in service) {
            if (service[u].product.meta) {
              if (service[u].product.meta.intituleFournisseur !== undefined){
                serv = serv + ' ' + service[u].product.meta.intituleFournisseur ? service[u].product.meta.intituleFournisseur : ''
              }
            }
          }
        }

        obj.services = serv

        this.json_data.push(obj)
      }
    }
  },

  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    },

    trackColor (color) {
      if (color === '') {
        return 'CLEAR'
      }

      if (color === 'ROSE') {
        return 'PINK'
      }

      if (color === 'VIOLET') {
        return 'PURPLE'
      }

      if (color === 'BLEU') {
        return 'BLUE'
      }

      if (color === 'BRUN') {
       return  'BROWN'
      }

      if (color === 'GRIS') {
        return 'GRAY'
      }

      if ('VERT') {
        return 'GREEN'
      }

      if (color === 'ORANGE') {
        return 'ORANGE'
      }

      if (color === 'ROUGE') {
        return 'RED'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.the_header{
  font-family: 'Roboto', sans-serif;
  color: #000000;
}
.buttons{
  height: 2rem;
}
</style>
